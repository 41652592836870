import './Notes.css'
import React from 'react';
import CoffeeQR from './bmc_qr.png';

// display a vertical list of links to external notes

function Notes() {
    return (
        <div className="page-content">
            <h1>Daniela's notes</h1>
            <p>Click on any course code to get the PDF with the notes. For any improvements of fixes contact me</p>
        <style>{'body { background-color: rgb(5, 0, 40); color: #c6c6c6;}'}</style>
            <ul class="notes-list">
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=CSE206/main.tex&command=pdflatex">CSE206</a></b>: Introduction to Formal Languages
                    (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/CSE206">source code</a> if you are curious)
                </li>
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=MAA207/main.tex&command=pdflatex">MAA207</a></b>: Series of Functions, Differential Equations
                    (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/MAA207">source code</a> if you wanna see my spaghetti)
                </li>
                <li><b><a href="https://stratus.binets.fr/apps/files/files/45797959?dir=/Bachelor/Courses/Year_2&openfile=true">MAA201</a></b>: Linear Algebra II (completions)
                     (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/MAA201">source code</a> if you want to improve it)
                </li>
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=MAA106/main.tex&command=pdflatex">MAA106</a></b>: Introduction to Numerical Analysis
                    (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/MAA106">source code</a> if you want to fork me on GitHub)
                </li>
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=LAB151/main.tex&command=pdflatex">LAB151</a></b>: Maths in Practice: Vector and Fourier Analysis (short reference)
                    (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/LAB151">source code</a> if you want to nitpick)
                </li>
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=MAA103/main.tex&command=pdflatex">MAA103</a></b>: Discrete Mathematics (full reference)
                    (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/MAA103">source code</a> if you don't have a life)
                </li>
                <li><b><a href="https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=MAA101/main.tex&command=pdflatex">MAA101</a></b>: Linear Algebra I (stuff I found very important)
                     (the <a href="https://github.com/UserCDP/LaTeX_notes/tree/main/MAA101">source code</a> because I am nice)
                </li>
            </ul>
            {/*Linear Algebra II compile link "https://latexonline.cc/compile?git=https://github.com/UserCDP/LaTeX_notes&target=MAA201/main.tex&command=pdflatex" */}
            {/* <BuyMeACoffe/> */}
            <script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="7yyzsbjsvp7" data-color="#FF5F5F" data-emoji=""  data-font="Cookie" data-text="Buy me a coffee (please)" data-outline-color="#000000" data-font-color="#ffffff" data-coffee-color="#FFDD00" ></script>
            <a class="img-container" href="https://www.buymeacoffee.com/7yyzsbjsvp7">
                <div class="img-text">Buy me a coffee</div>
                <img src={CoffeeQR} alt="Buy me a coffee" />
            </a>
        </div>
    );
}

export default Notes;
