import './Homepage.css';
import React from 'react';
import geoMarkers_logo from './geoMarkers_logo.png';
import languAI_logo from './languAI_logo.png';
import xmonad_config from './xmonad_config.png';
import i3config from './i3shot.png';

// import icons
import { BsEnvelopeAtFill } from "react-icons/bs";
import { BsGithub, BsLinkedin, BsMastodon, BsTelegram } from "react-icons/bs";

function cover() {
  return (
    <div class="tb-1">
      <div class="tbt-1">
        <div class="tt-1">UserCDP</div>
        <div class="ti-1">
          <a href="mailto:daniela@usercdp.com"><BsEnvelopeAtFill /></a>
          <a href="https://github.com/UserCDP"><BsGithub /></a>
          <a href="https://www.linkedin.com/in/daniela-cojocaru-63ab77250"><BsLinkedin /></a>
          <a href="https://mastodon.social/@UserCDP"><BsMastodon /></a>
          <a href="https://t.me/usercdp"><BsTelegram /></a>
        </div>
      </div>
    </div>
  );
}

function projects_list() {
  return (
    <div class="cb-1">
      <div class="subsection">Projects:</div>
      <div class="pb-1">
      <a class="project" href="https://cherry.usercdp.com">
          <div class="project-inner">
            <img src={languAI_logo} class="image" />
            <div class="project-front">
              <div class="title">Cherry Chat</div>
              <div class="description">An app using the OpenAI API for multilingual chat</div>
            </div>
          </div>
        </a>
        <a class="project" href="/geomarkers">
          <div class="project-inner">
              <img src={geoMarkers_logo} class="image" />
              <div class="project-front">
                <div class="title">Geo Markers</div>
                <div class="description">A app using the Google Maps API to highlight areas of interest</div>
              </div>
          </div>
        </a>
        <a class="project" href="https://github.com/UserCDP/i3dotfiles">
          <div class="project-inner">
            <img src={i3config} class="image" />
            <div class="project-front">
              <div class="title">Current i3wm config</div>
              <div class="description">The configuration I am currently using for i3</div>
            </div>
          </div>
        </a>
        <a class="project" href="https://github.com/UserCDP/xmonad_config">
          <div class="project-inner">
            <img src={xmonad_config} class="image" />
            <div class="project-front">
              <div class="title">Old XMonad config</div>
              <div class="description">My old XMonad configuration</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

function about() {
  return (
    <div class="ab-1">
      <div class="subsection">About me:</div>
      <ul class="abt-1">
        <li class="abt-2">The technology side of life</li>
        <ul class="abt-3">
          <li>Developing software since 2020</li>
          <li>Main languages: Python, JavaScript/TypeScript, C/C++, Haskell</li>
          <li>Main technologies: Linux, React, Django, PostgreSQL, AWS</li>
          <li>Development setup: Arch Linux with SwayWM and VSCode or Neovim</li>
        </ul>
        <li class="abt-2">The other side of life</li>
        <ul class="abt-3">
          <li>Majoring in Mathematics & Computer Science at l'X with 2025 as expected year of graduation</li>
          <li>Interested in: literature, languages and gardening</li>
        </ul>
      </ul>
    </div>
  );
}

function App() {
  return (
    // add the cover
    <div class="App">
      <style>{'body { background-color: rgb(5, 0, 40); color: #c6c6c6;}'}</style>
      {cover()}
      {projects_list()}
      {about()}
    </div>
  );
}

export default App;
