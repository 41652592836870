import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './geoMarkers.css'

const MapContainer = ({ coordinates }) => {
  const defaultProps = {
    center: {
        // set the center to the average of all the points
        lat: coordinates.reduce((acc, curr) => acc + curr.lat, 0) / coordinates.length,
        lng: coordinates.reduce((acc, curr) => acc + curr.lng, 0) / coordinates.length,
    },
    zoom: 5
  };

  return (
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDho7Ep2AhWKtbtNPinongVf-awIPimRmA' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          // Draw the polygon once the map has loaded
          new maps.Polygon({
            paths: coordinates,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map,
          });
        }}
      />
    </div>
  );
};

const PointInputForm = ({ pointCount, setCoordinates }) => {
  const emptyPoints = [...Array(pointCount)].map(() => ({ lat: 0, lng: 0 }));
  const [points, setPoints] = useState(emptyPoints);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCoordinates(points);
  };

  const handlePointChange = (idx, event) => {
    const newPoints = [...points];
    if (String(newPoints).includes(",")) {
        newPoints[idx][event.target.name] = parseFloat(event.target.value.replace(",", "."));
    }
    newPoints[idx][event.target.name] = parseFloat(event.target.value);
    setPoints(newPoints);
  };

  return (
    <form onSubmit={handleSubmit}>
      {points.map((point, idx) => (
        <div key={idx}>
          <label>Point {idx + 1}: </label>
          <input name="lat" type="number" step="0.0001" placeholder="Latitude" onChange={(event) => handlePointChange(idx, event)} required />
          <input name="lng" type="number" step="0.0001" placeholder="Longitude" onChange={(event) => handlePointChange(idx, event)} required />
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};

class PrintButton extends React.Component {
    handlePrintClick = () => {
        window.print();
    }
    render () {
        return (
            <button onClick={this.handlePrintClick}>Save/Print</button>
        );
    }
}

const App = () => {
  const [pointCount, setPointCount] = useState(null);
  const [coordinates, setCoordinates] = useState(null);

  const handlePointCountSubmit = (event) => {
    event.preventDefault();
    setPointCount(parseInt(event.target.pointCount.value));
  };

  return (
    <div className="App">
        <div className="information-prompt">
        Effortlessly outline specific regions on a map with our tool. 
        Start by specifying the number of corners your desired area has. 
        You'll then be prompted to provide the precise coordinates for each of these corners. 
        With this information, our tool will create a visually striking 
        highlight of your specified area directly on the map that you can
        save as PDF or print.
        </div>
      {pointCount === null ? (
        <form onSubmit={handlePointCountSubmit}>
          <label>Number of points:</label>
          <input name="pointCount" type="number" min="3" required />
          <button type="submit">Submit</button>
        </form>
      ) : coordinates === null ? (
        <PointInputForm pointCount={pointCount} setCoordinates={setCoordinates} />
      ) : (
        coordinates.length > 0 && <div> <MapContainer coordinates={coordinates} />
            <PrintButton />
            <table className='coordinates-table'>
                <thead>
                    <tr>
                        <th>Point</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                    </tr>
                </thead>
                <tbody>
                    {coordinates.map((coordinate, idx) => (
                        <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{coordinate.lat}</td>
                            <td>{coordinate.lng}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
      )}
    </div>
  );
}

export default App;
